import React from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  StyledTableCell,
  StyledTableRow,
  tableBoxShawdow,
} from "./TableStyles";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(value / 100);
};

const formatValue = (key, value) => {
  if (key === "margin" || key === "achRate") {
    return `${(value / 100).toFixed(2)}%`;
  } else if (key !== "store" && key !== "department" && key !== "date") {
    return formatCurrency(value);
  }
  return value;
};

const TableDisplay = ({ isClickable, tableData }) => {
  const navigate = useNavigate();
  const handleClick = (store) => {
    if (isClickable) {
      navigate(`/stores/${store}`);
    }
  };

  const dataKeys = Object.keys(tableData);

  const numRows = tableData[dataKeys[0]].length;

  return (
    <TableContainer component={Paper} sx={tableBoxShawdow}>
      <Table sx={{ minWidth: 250 }}>
        <TableHead>
          <TableRow>
            {dataKeys.map((key) => (
              <StyledTableCell key={key} align="center">
                {key}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: numRows }).map((_, index) => (
            <StyledTableRow
              key={index}
              onClick={() => isClickable && handleClick(tableData.store[index])}
              isClickable={isClickable}
            >
              {dataKeys.map((key) => (
                <StyledTableCell key={key + index} align="center">
                  {formatValue(key, tableData[key][index])}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableDisplay;
