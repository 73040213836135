import React from "react";
import Box from "@mui/material/Box";
import Table from "layouts/table/TableDisplay";
import Widget from "layouts/widget/Widget";
import Grid from "@mui/material/Grid";
import useTotalAchieve from "layouts/dashboard/data/widgetData/TotalAchieve";
import ChartDisplay from "layouts/chart/ChartDisplay";
import useSalesDetails from "layouts/dashboard/data/tableData/DailySalesData";
import useSalesDetailsByStores from "layouts/dashboard/data/chartData/salesAchByStores";
import { fontBold } from "styles/style";
import { Typography } from "@mui/material";
import { paddingTwenty } from "styles/style";
import {
  achieveDefault,
  barChartProps,
  marginDefault,
} from "./DetailByStoresStyle";

//Grid-size: - xs: > 0px; - sm: > 600px; - md: > 960px; - lg: > 1280px; - xl: > 1920px;
const DetailByStores = ({ branch }) => {
  const achieve = useTotalAchieve();
  const margin = useTotalAchieve();

  const achieveData = {
    ...achieveDefault,
    ...achieve,
  };

  const marginData = {
    ...marginDefault,
    ...margin,
  };

  const { data: dailySalesDetails, isSalesDetailsLoading } = useSalesDetails();
  const { data: salesDetailsByStores, isSalesDetailsByStoresLoading } =
    useSalesDetailsByStores();

  if (isSalesDetailsLoading || isSalesDetailsByStoresLoading) {
    return <div>Loading...</div>;
  }

  const barChartData = {
    ...barChartProps,
    data: salesDetailsByStores,
  };

  return (
    <Box>
      <Box sx={paddingTwenty}>
        <Typography sx={{ ...fontBold, ...paddingTwenty, fontSize: "30px" }}>
          {branch} Sales Overview
        </Typography>
        <Box>
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={10}>
                <Widget data={achieveData} />
              </Grid>
              <Grid item xs={12} lg={6} md={10}>
                <Widget data={marginData} />
              </Grid>
            </Grid>

            <Grid item xs={12} md={10}>
              <Typography style={{ ...fontBold, ...paddingTwenty }}>
                Daily Sales Details
              </Typography>
              <Table isClickable={false} tableData={dailySalesDetails} />
            </Grid>
            <Grid item xs={12} sm={10}>
              <ChartDisplay {...barChartData} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography style={{ ...fontBold, ...paddingTwenty }}>
                Sales Detail by Stores
              </Typography>
              <Table isClickable={false} tableData={salesDetailsByStores} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailByStores;
