export const convertDateForm = (date) => {
  return date.format("YYYY-MM-DD");
};

export const accumulateByItems = (data) => {
  return data.reduce((acc, item) => {
    const { sub_c_name, price, tax1 = 0.0, tax2 = 0.0, qt } = item;

    if (!acc[sub_c_name]) {
      acc[sub_c_name] = {
        categoryN: sub_c_name,
        totalPrice: price,
        totalTax1: tax1,
        totalTax2: tax2,
        totalQt: qt,
      };
    } else {
      acc[sub_c_name].totalPrice += price;
      acc[sub_c_name].totalQt += qt;
    }

    return acc;
  }, {});
};

export const cacluateTotals = (accumulateByItem) => {
  return Object.values(accumulateByItem).reduce(
    (acc, { totalPrice, totalTax1, totalTax2, totalQt }) => {
      acc.totalRevenue =
        (acc.totalRevenue || 0) + totalPrice * (1 + totalTax1 + totalTax2);
      acc.totalQuantity = (acc.totalQuantity || 0) + totalQt;
      return acc;
    },
    { totalRevenue: 0, totalQuantity: 0 },
  );
};
