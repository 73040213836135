import DbManager from "globalApi/DbManager";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import dayjs from "dayjs";
import { Box } from "@mui/material";

import SelectButton from "buttons/SelectButton";
import DateSelector from "buttons/DateSelector";
import VendorReportTable from "./VendorReportTable";
import {
  accumulateByItems,
  cacluateTotals,
  convertDateForm,
} from "utils/utils";
import { flexCenterStyles, whiteFont } from "../../styles/style";
import { Link, useNavigate } from "react-router-dom";

const VendorReport = ({ vendor }) => {
  const [vendorInfo, setVendorInfo] = useState([]);
  const [stores, setStores] = useState("");
  const [storesDepartments, setStoresDepartments] = useState("");
  const [store, setStore] = useState("");
  const [menuloading, setMenuLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [department, setDepartment] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedData, setSelectedData] = useState({});
  const [totalSalesNQt, setTotlaSalesNQt] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const getVendorsData = await DbManager.GET(
        `/achieve/vendorsStoreNDepart?vendor=${vendor}`
      );

      if (getVendorsData) {
        const storeNames = getVendorsData.map((item) => item.store_name);
        const departmentNames = [
          ...new Set(getVendorsData.map((item) => item.department_name)),
        ];
        setStores(storeNames);
        setStoresDepartments(departmentNames);
        setMenuLoading(false);
      } else {
        console.error("no data received");
      }
    };
    fetchData();
  }, [vendor]);

  const retriveDataBasedOnUserInfo = async (e) => {
    e.preventDefault();
    const convertedStartDate = convertDateForm(startDate);
    const convertedEndDate = convertDateForm(endDate);

    const retrieveData = await DbManager.GET(
      `/achieve/vendors?store=${store}&department=${department}&startDate=${convertedStartDate}&endDate=${convertedEndDate}`
    );
    if (retrieveData) {
      const accumulateByItem = accumulateByItems(retrieveData);
      const entireAccumulatedTotal = cacluateTotals(accumulateByItem);

      setTotlaSalesNQt(entireAccumulatedTotal);
      setVendorInfo(Object.values(accumulateByItem));
      const selectData = {
        store: store,
        department: storesDepartments,
        startDate: startDate,
        endDate: endDate,
      };

      setSelectedData(selectData);
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const productList = () =>
    vendorInfo.map((item) => {
      return { prodName: item.categoryN, prodQauntity: item.totalQt };
    });
  const linkToOrderPage = (e) => {
    e.preventDefault();
    console.log(productList);
    const filteredList = productList(vendorInfo);
    console.log(filteredList);

    navigate("order", { state: filteredList });
  };
  return (
    <Box>
      <Box
        sx={
          {
            // display: "flex",
            // justifyContent: "center",
            // flexDirection: "column",
            // alignContent: "center",
          }
        }
      >
        <Box
          sx={{
            display: "flex",
            marginTop: "20px ",
          }}
        >
          {menuloading === true ? (
            <></>
          ) : (
            <>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <SelectButton
                    setData={setStore}
                    selected={store}
                    title={"Store"}
                    data={stores}
                  />
                  <SelectButton
                    setData={setDepartment}
                    selected={department}
                    title={"Department"}
                    data={storesDepartments}
                  />
                </Box>
                <DateSelector
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                  startDate={startDate}
                />
              </Box>
            </>
          )}

          <Box
            sx={{
              marginLeft: "15px",
              display: "flex",
              justifyContent: "space-evenly",
              width: "200px",
            }}
          >
            <Button
              onClick={retriveDataBasedOnUserInfo}
              variant="contained"
              color="success"
              sx={{
                backgroundColor: "#129d58",
                width: "6vw",
                borderRadius: "10px",
              }}
            >
              조회
            </Button>{" "}
            <Button
              onClick={linkToOrderPage}
              variant="contained"
              color="success"
              sx={{
                backgroundColor: "#129d58",
                width: "6vw",
                borderRadius: "10px",
              }}
            >
              주문
            </Button>
          </Box>
        </Box>
        <VendorReportTable
          selectedData={selectedData}
          totalSalesNQt={totalSalesNQt}
          vendorInfo={vendorInfo}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default VendorReport;
