import { useState, useEffect } from "react";
import DbManager from "globalApi/DbManager";

const useDailySalesDetails = () => {
  const [data, setData] = useState({});
  const [isSalesDetailsLoading, setIsSalesDetailsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsSalesDetailsLoading(true);
      const resp = await DbManager.POST("/achieve/dailysales", {
        date: { start: "2024-01-01", end: "2024-01-01" },
      });
      console.log(resp);
      setData({
        date: resp.map((item) => item.date.trim()),
        margin: resp.map((item) => item.margin.toFixed(2)),
        profit: resp.map((item) => item.profit.toFixed(2)),
        purchaseAmount: resp.map((item) => item.purchaseAmount.toFixed(2)),
        salesAmount: resp.map((item) => item.salesAmount.toFixed(2)),
      });
      setIsSalesDetailsLoading(false);
    };
    fetchData();
  }, []);
  return { data, isSalesDetailsLoading };
};

export default useDailySalesDetails;
