import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import dayjs from "dayjs";
import VendorReportExportExcel from "utils/VendorReportExportExcel";
import { convertDateForm } from "utils/utils";
import {
  venderTableTitle,
  vendorReportHeader,
  vendorReportTitleInfoStyles,
} from "./TableStyles";
import { whiteFont } from "styles/style";
import { flexSpaceBetween } from "styles/style";

const VendorReportTable = ({
  selectedData,
  startDate,
  endDate,
  totalSalesNQt,
  vendorInfo,
  loading,
}) => {
  const tableCellTitle = ["구분", "매출액", "Tax1", "Tax2", "수량", "매출총액"];

  return (
    <Box>
      {loading === true ? (
        <></>
      ) : (
        <>
          <Box sx={vendorReportHeader}>
            <Box sx={vendorReportTitleInfoStyles}>
              <Box>매장: {selectedData.store}</Box>
              <Box>전체 총매출: ${totalSalesNQt.totalRevenue}</Box>
            </Box>
            <Box sx={vendorReportTitleInfoStyles}>
              <Box>부서: {selectedData.department}</Box>
              <Box>전체 총 수량: {totalSalesNQt.totalQuantity}</Box>
            </Box>
          </Box>
        </>
      )}

      <Box sx={venderTableTitle}>
        <strong>기간별 Group 매출액 집계현황</strong>
      </Box>
      <Box sx={flexSpaceBetween}>
        <Box>
          조회기간: {convertDateForm(dayjs(selectedData.startDate))} -{" "}
          {convertDateForm(dayjs(selectedData.endDate))}
        </Box>
        <VendorReportExportExcel
          vendorInfo={vendorInfo}
          selectedData={selectedData}
          totalSales={totalSalesNQt.totalSales}
          totalQt={totalSalesNQt.totalQt}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>

      <TableContainer variant="soft" sx={{ pt: 1, borderRadius: "sm" }}>
        <Table stripe="odd">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#129d58" }}>
              {tableCellTitle.map((tableCell, index) => (
                <TableCell key={tableCell + index} sx={whiteFont}>
                  {tableCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {loading === true ? (
            <></>
          ) : (
            <>
              <TableBody>
                {vendorInfo.map((row, index) => (
                  <TableRow key={row.totalPrice + index}>
                    <TableCell>{row.categoryN}</TableCell>
                    <TableCell>$ {row.totalPrice.toFixed(2)}</TableCell>
                    <TableCell>{row.totalTax1.toFixed(2)}</TableCell>
                    <TableCell>{row.totalTax2.toFixed(2)}</TableCell>
                    <TableCell>&nbsp;&nbsp;{row.totalQt}</TableCell>
                    <TableCell>
                      ${" "}
                      {row.totalPrice.toFixed(2) *
                        (1 + row.totalTax1 + row.totalTax2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VendorReportTable;
