import React, { useEffect, useState } from "react";
import OrderProduct from "./OrderProduct";
import { useLocation } from "react-router-dom";
import DbManager from "globalApi/DbManager";
import { Box, Button } from "@mui/material";
import GetOneItemFromObejct from "utils/GetOneItemFromObejct";

import EmailSender from "utils/EmailSender";
import OrderedProdctExcel from "utils/OrderedProdctExcel";

const OrderPage = () => {
  const [currentProductPriceList, setCurrentProductPriceList] = useState({});
  const [currentPageLoading, setCurrentPageLoading] = useState(false);
  const [currentOrderTable, setCurrentOrderTable] = useState({});
  const location = useLocation();
  const productListNQuantity = location.state;

  useEffect(() => {
    const productList = GetOneItemFromObejct({
      list: productListNQuantity,
      name: "prodName",
    });

    if (productList) {
      const fetchData = async () => {
        const getVendorsData = await DbManager.POST(
          "/product/currentProductPrice",
          {
            productList: productList,
          }
        );
        if (getVendorsData) {
          setCurrentProductPriceList(getVendorsData);
          setCurrentPageLoading(true);
        }
      };
      fetchData();
    }
  }, [productListNQuantity]);

  useEffect(() => {
    if (Object.keys(currentProductPriceList).length !== 0) {
      const reformatOrderState = currentProductPriceList?.map((item, index) => {
        return {
          ...item,
          Quantity: productListNQuantity[index].prodQauntity,
          Total: 0,
        };
      });
      setCurrentOrderTable(reformatOrderState);
    }
  }, [currentProductPriceList, productListNQuantity]);

  const orderByEmailWithExcel = async () => {
    const createdExcel = await OrderedProdctExcel({ data: currentOrderTable });

    EmailSender({ createdExcel: createdExcel });
  };

  return (
    <Box>
      {currentPageLoading === false ? (
        <></>
      ) : (
        <>
          <OrderProduct
            currentProductPriceList={currentProductPriceList}
            currentOrderTable={currentOrderTable}
            setCurrentOrderTable={setCurrentOrderTable}
          />
          <Button onClick={orderByEmailWithExcel}>주문하기</Button>
        </>
      )}
    </Box>
  );
};

export default OrderPage;
